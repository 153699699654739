import React, {useMemo, useState} from 'react';
import classes from './ContactUsRow.module.scss';
import {Button, Col, ConfigProvider, Form, Grid, Image, Row, Typography, Upload} from "antd";
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";
import SendForm from "../../../../components/SendForm/SendForm";

const ContactsUsRow = () => {
	const [form] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();

	return (
		<section id="contactUs" className={classes.container}>
			<div className={classes.innerContainer}>
				<Row align="stretch" className={classes.contentContainer}>
					<Col xs={{ span: 24, order: 2 }} xl={{ span: 8, order: 1 }}>
						<div className={classes.sideImage}>
							<div className={classes.darkOverlay}>
								<div className={classes.imageTextContainer}>
									<Typography.Text className={classes.imageSubTitle}>Остались вопросы?</Typography.Text>
									<Typography.Title className={classes.imageTitle} level={3}>
										Задайте их нам
									</Typography.Title>
									<Button className={classes.btn} size={breakpoints.md ? 'middle' : 'small'} block>
										Задать вопрос
									</Button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={{ span: 24, order: 1 }} xl={{ span: 16, order: 2 }} className={classes.contentColumn}>
						<AnimatedTypography.Paragraph className={classes.subTitle}>
							У вас уже есть готовый дизайн-проект?
						</AnimatedTypography.Paragraph>
						<AnimatedTypography.Title className={classes.title} level={2}>
							Отправьте его нам, и мы рассчитаем его стоимость
						</AnimatedTypography.Title>

						<SendForm />
					</Col>
				</Row>
			</div>
		</section>
	);
};

export default ContactsUsRow;
